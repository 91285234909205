/* You can add global styles to this file, and also import other style files */
@import 'https://library-sdb.apps.bancolombia.com/bds/6.17.5/bds.min.css';

.right-align {
  position: absolute;
  right: 0
}


.toast-top-center {
  margin-top: 40px;
  width: 400px !important;
  margin-right: 42% !important;
}

.imgClass{
  width: 300px;
  height: 200px;
  margin-top: -60px;
}

.customButton {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  border-style: none;
  cursor: pointer;
  padding: 12px 24px;
  outline: 0 none;
  text-transform: none;
  text-decoration: none;
  border-radius: 100px;
  overflow: hidden;
  height: auto;
  white-space: initial;
  font-family: "CIB Sans bold";
  font-weight: 700;
  letter-spacing: 0px;
  font-size: 18px 22px;
  margin-bottom: 5px;
  margin-right: 5px;
  &-primary{
    background-color: #fdda24;
  }
  &-secondary{
    background-color: #fff0;
    border: 1px solid #808285
  }
  color:#000000
}


.menu {
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;
}

.menu-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.menu.active .menu-content {
  display: block;
}


table {
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.center-text{
  text-align: center;
}

.table-container {
  min-height: 450px;
  overflow-x: auto;
}

.table-container-modal{
  overflow-x: auto;
}

.no-border{
  border: none;
}

.no-border:focus {
  outline: none;
  border-color: transparent;
}

.container {
  border:2px solid #ccc;
  white-space: nowrap;
  text-align: left;
  height: 170px;
  width: 200px;
  overflow-y: auto;
  overflow-x: auto;

}


.label-filter{
  margin-left: 2px;
}

.loader{
  width: 100%;
  height: 160px;
}


.centralize{
  display: grid;
  place-items: center;
}

.color-yellow{
  background-color: yellow;
}

.color-red{
  background-color: red;
}

